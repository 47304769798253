import React from 'react'
import { Paper, Avatar, Grid, Typography } from '@material-ui/core'

export default ({ name }) => (
  <Paper style={{ padding: '20px', margin: '0px 0px 16px 0px' }}>
    <Grid container>
      <Grid item xs={2}>
        <Avatar />
      </Grid>

      <Grid item xs={10}>
        <Typography variant={'subtitle1'}>{name}</Typography>
      </Grid>
    </Grid>
  </Paper>
)
