import React from 'react'
import { Paper, Typography, Button, Icon } from '@material-ui/core'

export default ({ children, url, title }) => (
  <a href={url || children} target={'_blank'}>
    <Paper
      elevation={0}
      style={{
        display: 'flex',
        margin: '16px 0px',
        backgroundColor: 'unset',
        border: '1px solid grey',
        opacity: '0.8',
      }}
    >
      <div
        style={{
          padding: '20px',
          flex: '1',
        }}
      >
        <Typography variant={'h6'}>{!!title ? title : '—'}</Typography>
      </div>
      <Button
        style={{ marginLeft: '10px', minWidth: '100px', textAlign: 'center' }}
      >
        <Icon>open_in_new</Icon>
      </Button>
    </Paper>
  </a>
)
