import { FacebookProvider, Like, EmbeddedVideo } from 'react-facebook';
import PostIntro from "../../../../src/components/PostIntro";
import PostRecommendation from "../../../../src/components/PostRecommendation";
import Point from "../../../../src/components/Point";
import PostList from "../../../../src/components/PostList";
import * as React from 'react';
export default {
  FacebookProvider,
  Like,
  EmbeddedVideo,
  PostIntro,
  PostRecommendation,
  Point,
  PostList,
  React
};