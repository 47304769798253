import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core';
import { brown, purple } from '@material-ui/core/colors';

import React from 'react';

export default ({ title, icon, palette = purple, items = [] }) => {
  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: palette[800],
        margin: '24px 0px',
      }}
    >
      <List>
        {title ? (
          <ListSubheader disableSticky={true} style={{ opacity: '0.98' }}>
            {title}
          </ListSubheader>
        ) : null}
        {items.map((item) => {
          const { primary, icon: iconLocal, secondary } = item;

          return (
            <ListItem
              key={primary}
              divider={
                items.indexOf(item) !== items.length - 1 ? 'true' : 'false'
              }
            >
              <ListItemIcon>
                <Icon
                  style={{
                    color: palette[100],
                    opacity: 0.7,
                  }}
                >
                  {iconLocal || icon}
                </Icon>
              </ListItemIcon>
              <ListItemText
                style={{ opacity: 0.97 }}
                primary={primary}
                secondary={secondary}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};
