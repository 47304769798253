import { red, orange } from '@material-ui/core/colors';
import Point from "../../../../src/components/Point";
import ProfileTwitter from "../../../../src/components/ProfileTwitter";
import PostIntro from "../../../../src/components/PostIntro";
import PostRecommendation from "../../../../src/components/PostRecommendation";
import PostList from "../../../../src/components/PostList";
import * as React from 'react';
export default {
  red,
  orange,
  Point,
  ProfileTwitter,
  PostIntro,
  PostRecommendation,
  PostList,
  React
};