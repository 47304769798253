import { Divider, Paper, Typography } from '@material-ui/core';
import { green, grey, indigo, pink, teal } from '@material-ui/core/colors';

import React from 'react';

export default ({ children, palette = teal }) => (
  <Paper
    elevation={1}
    style={{
      padding: '48px 80px',
      margin: '16px 0px',
      backgroundColor: palette[700],
      opacity: 0.9,
      border: `3px solid ${palette[900]}`,
    }}
  >
    <Typography
      align={'center'}
      variant={'h6'}
      style={{
        fontWeight: 'bold',
        lineHeight: 1.2,
        color: 'white',
      }}
    >
      {children}
    </Typography>
  </Paper>
);
