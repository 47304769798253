import React from 'react'
import { Typography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export default ({ children }) => (
  <Typography
    component={'p'}
    color={'textSecondary'}
    variant={'body1'}
    style={{ margin: '-4px 0px 16px 0px' }}
    gutterBottom
  >
    {children}
  </Typography>
)
